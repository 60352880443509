import { message } from 'antd';
import Axios from 'axios'

// product loader true
function loader(loading) {
    return dispatch => {
        dispatch(isLoading(loading));
    };
}
export const LOADING = 'LOADING';
export const isLoading = (loading) => {
    return {
        type: LOADING,
        payload: { loading }
    }
}

// get search results
export function getSearchResults(obj, goToNext) {
    return dispatch => {
        dispatch(isLoading(true))
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL,
            headers: {
                "Content-Type": "application/json"
            },
            data: obj
        }).then((res) => {
            dispatch(isLoading(false))
            dispatch(getSearchResultsSuccess(res.data.results))
        }).catch((err) => {
            dispatch(isLoading(false))
            if (err.response) {
                if (err.response.status === 429) {
                    message.error(err.response.data.message)
                }
            }
        });
    };
}
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const getSearchResultsSuccess = (data) => ({
    type: GET_SEARCH_RESULTS,
    payload: { data }
});

// get autofill results
export function getAutofillResults(val, empty) {
    return dispatch => {
        if (empty) {
            dispatch(getAutofillResultsSuccess([]))
        } else {
            if (val) {
                Axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_BACKEND_URL}autosuggest/?format=json&q=${val}/`,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then((res) => {
                    dispatch(getAutofillResultsSuccess(res.data.suggestions))
                }).catch((err) => {

                });
            } else {
                dispatch(getAutofillResultsSuccess([]))
            }
        }
    };
}
export const GET_AUTOFILL_RESULTS = 'GET_AUTOFILL_RESULTS';
export const getAutofillResultsSuccess = (data) => ({
    type: GET_AUTOFILL_RESULTS,
    payload: { data }
});