import React from 'react'
import { Route } from 'react-router-dom'
import Home from '../home/Home'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Layout from '../../Layout/Layout'
import SearchListing from '../searchListings/SearchListing'
import privacy from '../privacy/privacy'
import Terms from '../Terms/Terms'
import Cookie from '../Cookie/Cookie'
import About from '../About/About'
import Contact from '../Contact/Contact'
import SendFeedback from '../SendFeedback/SendFeedback'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div>
        <Layout>
          <Route exact path="/" component={Home} />
          <Route exact path="/search-listings" component={SearchListing} />
          <Route exact path="/privacy" component={privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/cookie-policy" component={Cookie} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/send-feedback" component={SendFeedback} />
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = ({ store }) => ({
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {

    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
