import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPage } from '../../modules/Actions/commonActions';

import './Terms.scss'

function Terms(props) {

    useEffect(() => {
        props.setPage("search-listings")
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="TermsMain">
            <h2><b>Terms and Use for YoMiiGo</b></h2>
            <p>YoMiiGo is a property of the EnGeniux Corporation. </p>
            <p>PLEASE READ YOMIIGO  TERMS AND CONDITIONS OF USE CAREFULLY FOR THE ENGENIUX.COM WEBSITE.</p>
            <h3><b>BEFORE USING THIS SITE.</b></h3>
            <p>This site is free to use by our visitors. And by using this site, you the user are agreeing to comply with and be bound by the following terms of use. After reviewing the following terms and conditions thoroughly, if you do not agree to the terms and conditions, please do not use this site.</p>
            <h3><b>Acceptance of Agreement</b></h3>
            <p>You agree to the terms and conditions outlined in this Terms and Conditions of use Agreement (Agreement) with respect to our site (the YOMIIGO ). This Agreement constitutes the entire and only agreement between us and you and supersedes all prior or contemporaneous agreements, representations, warranties and understandings with respect to the YOMIIGO. the site, the content, free product samples or freebie offers or services provided by or listed on the YOMIIGO. the site, and the subject matter of this Agreement. This Agreement may be amended by us at any time and at any frequency without specific notice to you. The latest Agreement will be posted on the YOMIIGO. site and you should review this Agreement prior to using the YOMIIGO. site.</p>
            <h3><b>Copyright</b></h3>
            <p>The content, organization, graphics, design, and other matters related to the YOMIIGO are protected under applicable copyrights and other proprietary laws, including but not limited to intellectual property laws. The copying, reproduction, use, modification or publication by you of any such matters or any part of the YOMIIGO is strictly prohibited, without our express prior written permission.</p>
            <h3><b>Deleting and Modification</b></h3>
            <p>We reserve the right in our sole discretion, without any obligation and without any notice requirement to you, to edit or delete any documents, information or other content appearing on the YOMIIGO. site, including this Agreement.</p>
            <h3><b>Indemnification</b></h3>
            <p>You agree to indemnify, defend and hold us, our officers, our share holders, our partners, attorneys and employees harmless from any and all liability, loss, damages, claim and expense, including reasonable attorney’s fees, related to your violation of this Agreement or use of the Site.</p>
            <h3><b>Disclaimer</b></h3>
            <p>THE CONTENT, SERVICES, FREE PRODUCT SAMPLES AND FREEBIE OFFERS FROM OR LISTED THROUGH THE SITE ARE PROVIDED “AS-IS,” “AS AVAILABLE,” AND ALL WARRANTIES, EXPRESS OR IMPLIED, ARE DISCLAIMED, INCLUDING BUT NOT LIMITED TO THE DISCLAIMER OF ANY IMPLIED WARRANTIES OF TITLE, NON- INFRINGEMENT, MERCHANTABILITY, QUALITY AND FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO THIS SITE AND ANY WEBSITE WITH WHICH IT IS LINKED. THE INFORMATION AND SERVICES MAY CONTAIN BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS. WE HAVE NO LIABILITY WHATSOEVER FOR YOUR USE OF ANY INFORMATION OR SERVICE. IN PARTICULAR, BUT NOT AS A LIMITATION, WE ARE NOT LIABLE FOR ANY INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LOSS OF MONEY, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE NEGATION OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN US AND YOU THE USER. THIS SITE AND THE INFORMATION WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US THROUGH THE SITE SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT. THE INFORMATION AND ALL OTHER MATERIALS ON THE SITE ARE PROVIDED FOR GENERAL INFORMATION PURPOSES ONLY AND DO NOT CONSTITUTE PROFESSIONAL ADVICE. IT IS YOUR RESPONSIBILITY TO EVALUATE THE ACCURACY AND COMPLETENESS OF ALL INFORMATION AVAILABLE ON THIS SITE OR ANY WEBSITE WITH WHICH IT IS LINKED.</p>
            <h3><b>Limits</b></h3>
            <p>All responsibility or liability for any damages caused by viruses contained within the electronic file containing the form or document is disclaimed. We will not be liable to you for any incidental, special or consequential damages of any kind that may result from use of or inability to use the site.</p>
            <h3><b>Third-Party Website</b></h3>
            <p>All rules, terms and conditions, other policies (including privacy policies) and operating procedures of third-party linked websites will apply to you while on such websites. We are not responsible for the content, accuracy or opinions express in such Websites, and such Websites are not investigated, monitored or checked for accuracy or completeness by us. Inclusion of any linked Website on our Site does not imply approval or endorsement of the linked Website by us.</p>
            <p>This YOMIIGO. site and the third-party linked websites are independent entities and neither party has authority to make any representations or commitments on behalf of the other. If you decide to leave our Site and access these third-party linked sites, you do so at your own risk.</p>
            <h3><b>Third-Party Products and Services</b></h3>
            <p>We advertise third-party linked websites from which you may purchase or otherwise obtain certain sample goods, freebie offerings or free trial services. You understand that we do not operate or control the products, free offerings or services offered by third-party linked websites.</p>
            <p>Third-party linked websites are responsible for all aspects of order processing, fulfillment, billing and customer service. We are not a party to the transactions entered into between you and third-party linked websites. You agree that use of such third-party linked websites is at your sole risk and is without warranties of any kind by us, expressed, implied or otherwise. Under no circumstances are we liable for any damages arising from the transactions between you and third-party linked websites or for any information appearing on third-party linked websites or any other site linked to or from our site.</p>
            <h3><b>General Information</b></h3>
            <p>You agree that all actions or proceedings arising directly or indirectly out of this agreement, or your use of the site or any sample products, freebie offers or services obtained by you through such use, shall be litigated in the circuit court of Anniston Calhoun County, Alabama or the United States District Court for the Central District of Alabama. You are expressly submitting and consenting in advance to such jurisdiction in any action or proceeding in any of such courts, and are waiving any claim that Anniston, Alabama or the central district of Alabama is an inconvenient forum or an improper forum based on lack of venue. This site (YOMIIGO ) is controlled by the EnGeniux Corporation of the State of Delaware, USA. As such, the laws of Alabama will govern the terms and conditions contained in this Agreement and elsewhere throughout the YOMIIGO. site, without giving effect to any principles of conflicts of laws.</p>
            
        </div>
    )
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setPage
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Terms)