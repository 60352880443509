import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPage } from '../../modules/Actions/commonActions';

import './About.scss'

function About(props) {

    useEffect(() => {
        props.setPage("search-listings")
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="aboutMain">
            <h2><b>ABOUT YoMiiGo</b></h2>
            <p>YoMiiGo is the first next-generation live AI web metasearch engine that rewards its community and advertisers for searches and engagement.  Our system is designed to empower businesses with the marketing tools needed to succeed and help our consumers locate products and services they need daily. The YoMiiGo search engine will be in Preview mode for the next 6 to 12 months, but feel free to use the site each day. We will make monthly updates and upgrades. Your input is vital to helping us build a great product for our entire community. Join our YoMiiGo community to help test, develop and improve our system.</p>
        </div>
    )
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setPage
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(About)