import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Logo from '../../assets/Images/Logo.png'
import { NavLink } from 'react-router-dom'

import './footer.scss'

function Footer(props) {
    return (
        <div className="footerMain">
            <div className="colorFullLines">
                <div className="one"></div>
                <div className="two"></div>
                <div className="three"></div>
                <div className="four"></div>
                <div className="five"></div>
            </div>
            <div className="footerContent">
                <div className="left">
                    {props.page === "search-listings" &&
                        <img src={Logo} />
                    }
                    {/* <div><NavLink to="#">Help</NavLink></div> */}
                    <div><NavLink to="/send-feedback">Send Feedback</NavLink></div>
                    <div><NavLink to="/privacy">Privacy</NavLink></div>
                    <div><NavLink to="/terms">Terms</NavLink></div>
                    <div><NavLink to="/cookie-policy">Cookie Policy</NavLink></div>
                    <div><NavLink to="/about">About Yomiigo</NavLink></div>
                    <div><NavLink to="/contact">Contact</NavLink></div>
                    <div><NavLink to="#">Blog</NavLink></div>
                </div>
                {props.page !== "search-listings" &&
                    <div className="right">
                        <div>Copyright © 2021 YoMiiGo - All Rights Reserved.</div>
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer)