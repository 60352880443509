import Axios from 'axios'
import { GET_AUTOFILL_RESULTS, GET_SEARCH_RESULTS, LOADING } from '../Actions/searchListingActions';

const initialState = {
    results: false,
    autoFill: [

    ]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                loading: action.payload.loading,
            };
        case GET_SEARCH_RESULTS:
            return {
                ...state,
                results: action.payload.data
            };
        case GET_AUTOFILL_RESULTS:
            return {
                ...state,
                autoFill: action.payload.data
            };
        default:
            return state
    }
}

