import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setInputValue, setPage } from '../../modules/Actions/commonActions';
import LogoBackWhite from "../../assets/Images/LogoBackWhite.png"
import add1 from "../../assets/Images/searchlist/add1.png"
import add2 from "../../assets/Images/searchlist/add2.png"

import './searchListing.scss'
import { AiOutlineDoubleRight } from 'react-icons/ai';
import { FaPhoneSquare } from 'react-icons/fa';
import { BsThreeDotsVertical } from 'react-icons/bs';

import callIcon from '../../assets/AllIcons/callIcon.svg'
import { getSearchResults } from '../../modules/Actions/searchListingActions';
import Skeleton from 'react-loading-skeleton';

function SeachListing(props) {

    useEffect(() => {
        props.setPage("search-listings")
        const urlParams = new URLSearchParams(window.location.search);
        let value = urlParams.get("value")
        document.title = "YoMiiGo | Search Engine"
        props.setInputValue(value)
        let obj = {
            query: value
        }
        props.getSearchResults(obj)
    }, [])

    return (
        <div className="searchListMain">
            <div className="badgeDiv">
                <button className="badge"><img src={LogoBackWhite} /><AiOutlineDoubleRight /></button>
            </div>


            {/* Listing section start */}
            <div className="resultListings">
                <div className="head">
                    <h3>Web Result</h3>
                    <span>NEW</span>
                </div>

                {!props.loading ? props.searchResults && props.searchResults.map((a, i) => {
                    return (

                        <div key={i} className="list">
                            <BsThreeDotsVertical />
                            <a href={a.url}>{a.title}</a>
                            <span className="span1">{a.url}</span>
                            <p>{a.content && a.content.length > process.env.REACT_APP_CONTENT_SIZE ? `${a.content.slice(0, process.env.REACT_APP_CONTENT_SIZE)}...` : a.content}</p>
                            {/* {a.isAdd ?
                                <span className="span2">Ads by Engeniux - <a href>privacy</a></span>
                                :
                                null
                            } */}
                        </div>
                    )
                })
                    :
                    // Skeleton loader
                    [1, 2, 3, 4, 5, 6].map((a, i) => {
                        return (
                            <div key={i} className="list">
                                <Skeleton style={{ position: "absolute", right: 0 }} circle={true} height={20} width={20} />
                                <Skeleton width="50%" />
                                <Skeleton width="70%" />
                                <Skeleton width="100%" count={3} />
                            </div>
                        )
                    })
                }
            </div>
            {/* Listing section end */}


            {/* adds and promotion section start */}
            <div className="addsDiv">
                <a href="mailto:Hello@YoMiiGo.com"><button className="promoButton" onClick={() => window.location = "Hello@YoMiiGo.com"}>Advertise Here</button></a>
                <div className="add1">
                    <img src={add1} />
                    <p><a href="mailto:Hello@YoMiiGo.com">Advertise Here</a></p>
                </div>
                {/* <div className="add2">
                    <div className="colorFullLines">
                        <div className="one"></div>
                        <div className="two"></div>
                        <div className="three"></div>
                        <div className="four"></div>
                        <div className="five"></div>
                    </div>
                    <div className="add">
                        <div className="firstDiv">
                            <div className="greenDot"></div>
                            <div className="threeDots">
                                <div className="one"></div>
                                <div className="two"></div>
                                <div className="three"></div>
                            </div>
                        </div>
                        <img className="img" src={add2} />
                        <p>Hello. I,m the EnGeniux A.I. support rep. How can i help you today?</p>
                        <div className="buttonDiv">
                            <button>More Info</button>
                            <button>Go to Website</button>
                        </div>
                        <img src={callIcon} />
                    </div>
                </div> */}
                <div className="add2 add3">
                    <div className="colorFullLines">
                        <div className="one"></div>
                        <div className="two"></div>
                        <div className="three"></div>
                        <div className="four"></div>
                        <div className="five"></div>
                    </div>
                    <div className="add">
                        {/* <div className="firstDiv">
                            <div className="greenDot"></div>
                            <div className="threeDots">
                                <div className="one"></div>
                                <div className="two"></div>
                                <div className="three"></div>
                            </div>
                        </div>
                        <h3>Allysa's</h3>
                        <img src={callIcon} /> */}
                        <a target="_blank" href="https://fgscards.com/"><img src={require("../../assets/Images/searchlist/FGS.png")} /></a>
                    </div>
                </div>
            </div>
            {/* adds and promotion section end */}


        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        searchResults: store.searchListingStore.results,
        loading: store.searchListingStore.loading
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setPage,
            setInputValue,
            getSearchResults
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SeachListing)