import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StoreIcon from '../../assets/Images/header/storeIcon.png'
import AdvertiseIcon from '../../assets/Images/header/advertiseIcon.png'
import UnitedStates from '../../assets/AllIcons/usFlagIcon.svg'
import InputSuffix from '../../assets/Images/header/inputSuffix.png'
import { AiFillStar, AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs';

import './header.scss'
import { withRouter } from 'react-router';
import { setPage } from '../../modules/Actions/commonActions';
import { getAutofillResults, getSearchResults } from '../../modules/Actions/searchListingActions';
import { Drawer } from 'antd';

import Logo from '../../assets/Images/Logo.png'

function Header(props) {

    let [inputVal, setInputVal] = useState("")
    let [autoFill, setAutoFill] = useState(false)

    useEffect(() => {
        setInputVal(props.inputValue)
    }, [props.inputValue])

    const onEnter = (keyCode) => {
        if (keyCode === 13) {
            setAutoFill(false)
            search(inputVal)
        }
    }

    const onChangeVal = (e) => {
        setInputVal(e.target.value)
        props.getAutofillResults(e.target.value)
        if (!autoFill) {
            setAutoFill(true)
        }
    }

    const search = (val) => {
        props.history.push(`/search-listings?value=${inputVal}`)
        props.setPage("search-listings")
        let obj = {
            query: val
        }
        props.getSearchResults(obj)
    }

    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const itemClick = (a) => {
        setInputVal(a)
        search(a)
        props.history.push(`/search-listings?value=${a}`)
    }

    useEffect(() => {
        document.addEventListener("click", (e) => {
            setAutoFill(false)
        })
    }, [])

    return (
        <div className="headerMain">
            {props.page !== "search-listings" ?
                <div className="headerLeftSide">
                    <div>
                        <img src={StoreIcon} /> Yomiigo Store
                    </div>
                    <div>
                        <img src={AdvertiseIcon} /> Advertise
                    </div>
                </div>
                :
                <div className="headerLeftSideSearch">
                    <label className="headerSearchInput">
                        <img src={InputSuffix} />
                        <input
                            value={inputVal}
                            onChange={(e) => setInputVal(e.target.value)}
                            onKeyUp={(e) => onEnter(e.keyCode)}
                            onChange={(e) => onChangeVal(e)}
                        />
                        <AiOutlineClose onClick={() => setInputVal("")} />
                        <BsSearch onClick={() => search(inputVal)} />

                        {inputVal && autoFill && props.autoFill && props.autoFill.length ?
                            <div className="dropDown">
                                {props.autoFill.map((a, i) => {
                                    return (
                                        <div onClick={() => itemClick(a)} key={i} className="dropDownItem">{a}</div>
                                    )
                                })}
                            </div>
                            : null}
                    </label>
                    <div className="threeDots">
                        <div className="one"></div>
                        <div className="two"></div>
                        <div className="three"></div>
                    </div>
                </div>
            }
            <div className="headerRightSide">
                {/* {props.page === "search-listings" &&
                    <button>Install Yomiigo</button>
                } */}
                <div>Login</div>
                <div><AiFillStar className="icon" /></div>
                <div><img src={UnitedStates} /></div>
                <div><AiOutlineMenu onClick={showDrawer} className="icon" /></div>
            </div>

            {/* Side drawer start */}
            <Drawer
                title={<img src={Logo} />}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={window.screen.width < 600 ? visible : false}
            >
                {props.page !== "search-listings" &&
                    <div className="drawerContent">
                        <div>
                            <img src={StoreIcon} /> Yomiigo Store
                        </div>
                        <div>
                            <img src={AdvertiseIcon} /> Advertise
                        </div>
                    </div>
                }
            </Drawer>
            {/* Side drawer end */}

        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        inputValue: store.commonStore.inputValue,
        autoFill: store.searchListingStore.autoFill
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setPage,
            getSearchResults,
            getAutofillResults
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Header))