import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Footer from '../common/Footer/Footer';
import Header from '../common/Header/Header';
import './layout.scss'

function Layout(props) {

    return (
        <>
            <Header page={props.page} />
            <div className="layoutMain">
                {props.children}
            </div>
            <Footer page={props.page} />
        </>
    )
}

const mapStateToProps = (store) => {
    return {
        page: store.commonStore.page
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Layout)