import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPage } from '../../modules/Actions/commonActions';

import './Contact.scss'

function Contact(props) {

    useEffect(() => {
        props.setPage("search-listings")
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="contactMain">
            <h2><b>Contact us at <br/> <a href="mailto:support@yomiigo.com">support@yomiigo.com</a></b></h2>
        </div>
    )
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setPage
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact)