import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Logo from '../../assets/Images/Logo.png'
import { BsSearch } from "react-icons/bs"
import "./home.scss"
import { setPage } from '../../modules/Actions/commonActions'
import { getAutofillResults } from '../../modules/Actions/searchListingActions'

function Home(props) {
  let [searchVal, setSearchVal] = useState("")
  let [autoFill, setAutoFill] = useState(false)

  const onEnter = (keyCode) => {
    if (keyCode === 13) {
      setAutoFill(false)
      search(searchVal)
    }
  }

  useEffect(() => {
    props.getAutofillResults(searchVal, true)
  }, [])

  const onChangeVal = (e) => {
    setSearchVal(e.target.value)
    props.getAutofillResults(e.target.value)
    if (!autoFill) {
      setAutoFill(true)
    }
  }

  const search = (a) => {
    goToNext(a)
  }

  const goToNext = (val) => {
    props.history.push(`/search-listings?value=${val}`)
    props.setPage("search-listings")
  }

  useEffect(() => {
    props.setPage("home")
  }, [])

  const itemClick = (a) => {
    setSearchVal(a)
    search(a)
  }

  useEffect(()=>{
    document.addEventListener("click",(e)=>{
      setAutoFill(false)
    })
  },[])

  return (
    <div className="homeMain">


      {/* Center image section "YoMiiGo" start */}
      <div className="logoImage">
        <img src={Logo} />
      </div>
      {/* Center image section "YoMiiGo" end */}


      {/* Launching soon section start */}
      <div className="launchingSoon">
        {/* <h2><b><i>Launching Soon...</i></b></h2>
        <p><b>The YoMiiGo engine is built around providing great service and<br /> cost-effective ad options for business and entrepreneurs.</b></p> */}
      </div>
      {/* Launching soon section end */}


      {/* Search Input start */}
      <div className="inputDiv">
        <input
          value={searchVal}
          onChange={(e) => onChangeVal(e)}
          placeholder="What are you looking for, friend?"
          onKeyUp={(e) => onEnter(e.keyCode)}
        />
        <BsSearch onClick={()=>search(searchVal)} />
        {searchVal && autoFill && props.autoFill && props.autoFill.length ?
          <div className="hr"><div></div></div>
          : null}
        {searchVal && autoFill && props.autoFill && props.autoFill.length ?
          <div className="dropDown">
            {props.autoFill.map((a, i) => {
              return (
                <div onClick={() => itemClick(a)} key={i} className="dropDownItem">{a}</div>
              )
            })}
          </div>
          : null}
      </div>
      {/* Search Input end */}


      <div className="afterInputText">
        <p>Yomiigo means, "Hello, Friends"</p>
      </div>
    </div>
  )
}

const mapStateToProps = (store) => {
  return {
    autoFill: store.searchListingStore.autoFill
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPage,
      getAutofillResults
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
