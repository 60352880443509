import Axios from 'axios'

// product loader true
function loader(loading) {
    return dispatch => {
        dispatch(isLoading(loading));
    };
}
export const LOADING = 'LOADING';
export const isLoading = (loading) => {
    return {
        type: LOADING,
        payload: { loading }
    }
}

// Set page
export function setPage(page) {
    return dispatch => {
        dispatch(setPageSucces(page));
    };
}
export const SET_PAGE = 'SET_PAGE';
export const setPageSucces = (page) => {
    return {
        type: SET_PAGE,
        payload: { page }
    }
}

// Set input value
export function setInputValue(value) {
    return dispatch => {
        dispatch(setInputValueSucces(value));
    };
}
export const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
export const setInputValueSucces = (value) => {
    return {
        type: SET_INPUT_VALUE,
        payload: { value }
    }
}