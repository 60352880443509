import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPage } from '../../modules/Actions/commonActions';

import './SendFeedback.scss'

function SendFeedback(props) {

    useEffect(() => {
        props.setPage("search-listings")
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="sendFeedbackMain">
            <h2>
                <b>Help us improve YoMiiGo with feedback and features you would like to see. We're still developing but we still want to hear from our community. Contact <a href="mailto:hello@yomiigo.com">hello@yomiigo.com</a></b>
            </h2>
        </div>
    )
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setPage
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SendFeedback)