import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'
import 'antd/dist/antd.css';
import './index.css'
import ReactGA from 'react-ga';

import { createBrowserHistory } from 'history';
import { Router } from 'react-router'

const TRACKING_ID = "G-42QQKJQKB6"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const historyy = createBrowserHistory();

historyy.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

require('dotenv').config()

const target = document.querySelector('#root')

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <Router history={historyy}>
          <App />
        </Router>
      </div>
    </ConnectedRouter>
  </Provider>,
  target
)
