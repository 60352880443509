import Axios from 'axios'
import { LOADING, SET_INPUT_VALUE, SET_PAGE } from '../Actions/commonActions';

const initialState = {
  page: "",
  inputValue: ""
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.payload.value,
      };
    default:
      return state
  }
}

